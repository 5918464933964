@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';

.FacilitatorGameWinner {
	position: absolute;
	top: 0.833em;
	left: 55.5em; 
	width: 18em;
	border: 1px solid #0F635B;
	border-radius: 0.278em;
	padding: 1em 1.33em;
	color: #0F635B;
	.FacilitatorGameWinner-button {
		margin: 0.5em 1em;
		width: 10em;
	}
	.FacilitatorGameWinner-title {
		span {font-weight: 600;}
	}
	.FacilitatorGameWinner-text {
		span {font-size: 0.78em;}
	}

	.FacilitatorGameWinner-popup {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#7ABCEA, 0.81);
		@include flex('center', 'center');
	}
}