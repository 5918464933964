@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Feedback {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	background-image: linear-gradient(rgba(black, 0), rgba(black, 0.2), rgba(black, 0.5));
	background-size: 100% 7.25em;
	background-position: bottom center;
	z-index: 12;
	.Feedback-content {
		position: absolute;
		left: calc(50% - 8.75em);
		bottom: 1.39em;
		width: 17.5em;
		min-height: 3.78em;
		border-radius: 0.56em;
		background: white;
		padding: 1em 1em 1em 4.5em;
		text-align: left;
		p {
			margin: 0;
			font-size: 0.85em;
			color: #11466B;
		}
	}

	&.manager {
		.Feedback-content::after {
			content: '';
			position: absolute;
			bottom: calc(50% - 1.665em);
			left: 0.33em;
			width: 3.33em;
			height: 3.33em;
			border-radius: 100%;			
			background-color: #40E2B6;
			background-image: url('../../../assets/images/characters/manager-1.svg'), radial-gradient(#40E2B6, #239D94);
			background-position: top 0.3em left -0.2em, center center;
			background-size: 5em auto, auto auto;
		}
	}
}
