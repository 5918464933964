@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Home {
	position: relative;
	height: 100%;
	padding: 0.5em 1em 0em 1em;
	@include background-shapes();

	.Home-character {
		position: absolute;
		bottom: 0;
		width: 100%;
		text-align: center;
	}
	.Home-email {
		text-align: center;
		span {
			font-size: 0.67em;
			font-weight: 400;
			color: #33E3B5;
		}
	}
	.Home-logoutBtn {
		margin: 0.33em auto;
		width: 4.44em;
	}
	.Home-regionBtn,
	.Home-highscoreBtn {
		position: absolute;
		width: 3.5em;
		height: 3.5em;
		top: 0;
		background-size: 1.89em auto;
		background-position: center center;
		cursor: pointer;
	}
	.Home-regionBtn {
		left: 0;
		background-image: url('../../../assets/images/icon-region.svg');
	}
	.Home-highscoreBtn {
		right : 0;
		background-image: url('../../../assets/images/icon-trophy.svg');
	}

	.Home-title {
		margin-top: 1.5em;
		margin-bottom: 1.5em;
		text-align: center;
		p {
			margin: 0;
			text-transform: uppercase;
			font-size: 1.55em;
			line-height: 1.2;
			font-weight: 700;
		}
	}

	.Home-text {
		text-align: left;
		padding: 0 calc(50% - 7.25em);
		margin-bottom: 1em;	
		font-weight: 600;
	}

	.Home-modules {
		text-align: left;
		padding: 0 calc(50% - 7.25em);
		.Home-module {
			position: relative;
			border-radius: 0.25em;
			color: #0F635B;
			background-color: white;
			margin-bottom: 0.5em;
			padding: 0.5em 0.83em;
			cursor: pointer;
			.Home-moduleTitle {
				font-weight: 700;
			}
			.Home-moduleDescription {
				font-size: 0.89em;
				font-weight: 400;
			}
			&.locked {
				cursor: not-allowed;
				@include opacity(0.5);
				&::after {
					content: '';
					position: absolute;
					top: 0.33em;
					right: 0.33em;
					width: 1.11em;
					height: 1.11em;
					background-image: url('../../../assets/images/icon-lock.svg');
				}
			}
		}
	}

	.Home-resetBtn {
		position: absolute;
		width: 5em;
		bottom: 1em;
		right: 1em;
	}
}

@media (orientation: portrait) and (min-aspect-ratio: 375/667) { // wider portrait than design, scale font size after height
	.Home {
    font-size: calc(18 * ((100vh / 667)));
  }
}
