@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Login {
	position: relative;
	height: 100%;
	padding: 1em;
	overflow: auto;
	.Login-title {
		margin-top: 2em;
		text-align: center;
		p {
			margin: 0;
			text-transform: uppercase;
			font-size: 1.55em;
			line-height: 1.2;
			font-weight: 700;
		}
	}

	.Login-loginBtn {
		margin: 3em auto;
		width: 10em;
	}
}

@media (orientation: portrait) and (min-aspect-ratio: 375/667) { // wider portrait than design, scale font size after hide
	.Login {
    font-size: calc(18 * ((100vh / 667)));
  }
}
