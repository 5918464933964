@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.MultipleChoice {
	position: relative;
	height: 100%;
	padding-top: 2em;
	overflow: hidden;
	@include background-grey();

	.MultipleChoice-content {
		position: absolute;
		top: 5em;
		bottom: 0;
		width: 20.5em;
		left: calc(50% - 10.25em);
		overflow: auto;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
		@include hide-scrollbar();
	}
	.MultipleChoice-scrollDown {
		position: fixed;
		bottom: 0.1em;
		left: calc(50% - 1.375em);
		width: 2.75em;
		height: 2.75em;
		background-image: url('../../../../assets/images/modules/icon-down.svg');
		cursor: pointer;
	}
	.MultipleChoice-intro {
		position: relative;
		width: 17.5em;
		margin: 0 auto;
	}

	.MultipleChoice-options {
		// position: absolute;
		width: 20.5em;
		left: calc(50% - 10.25em);
		margin: 0.75em auto auto auto;
		padding: 0 1.5em;
		// overflow: auto;
		
		.MultipleChoice-optionsWrap {
			position: relative;			
			.MultipleChoice-option {
				position: relative;
				margin-bottom: 1.25em;
				cursor: pointer;
				@include button-grey();
				@include flex('center', 'center');
				@include no-select();
				&.animateCorrect {@include animateCorrect();}
				&.animateWrong {@include animateWrong();}
				&.disabled {cursor: not-allowed;}
			}
		}	
	}

	&:not(.images) {
		.MultipleChoice-options .MultipleChoice-optionsWrap {
			.MultipleChoice-option {
				&.selected {@include button-green();}
				&.correct,
				&.animateCorrect {@include button-green();}
				&.wrong,
				&.animateWrong {@include button-red();}
				&.completed {
					// @include grey-button();
					cursor: not-allowed;
				}
			}
		}
	}

	&.images {
		.MultipleChoice-options {
			padding: 0;
			.MultipleChoice-optionsWrap {
				.MultipleChoice-option { 
					min-height: auto;
					background: none;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					border: none;
					margin: 0;
					padding: 0;
					border-radius: 0;
					@include box-shadow(0, 0, 0, 0, transparent);
					span {
						display: none;
						&:nth-child(2) {
							position: absolute;
							bottom: 0.25em;
							left: 0.25em;
							width: 1.5em;
							height: 1.5em;
						}
					}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {
						border: none;	
						@include box-shadow(0, 0, 0, 0, transparent);
						span:nth-child(2) {display: block;}
					}
					&.correct,
					&.animateCorrect {
						span:nth-child(2) {
							background-image: url('../../../../assets/images/modules/icon-check2.svg');
						}
					}
					&.wrong,
					&.animateWrong {
						span:nth-child(2) {
							background-image: url('../../../../assets/images/modules/icon-error.svg');
						}
					}
				}
			}
		}
	}

	&.game-1-email-wrong-address {
		.MultipleChoice-options {
			width: 18.25em;
			left: calc(50% - 9.125em);
			.MultipleChoice-optionsWrap {
				padding: 0 0.5em;
				.MultipleChoice-option {
					width: 100%;
					background-size: 100% auto;
					background-position: top center;
					margin-bottom: 0.78em;
					span {
						&:nth-child(2) {
							position: absolute;
							bottom: calc(50% - 1em);
							left: auto;
							right: -0.5em;
							width: 2em;
							height: 2em;
						}
					}
					&.option-1 {
						height: 8.61em;
						background-image: url('../../../../assets/images/modules/tasks/multiple-choice/game-1-email-wrong-address/option-1.svg');
					}
					&.option-2 {
						height: 10.75em;
						background-image: url('../../../../assets/images/modules/tasks/multiple-choice/game-1-email-wrong-address/option-2.svg');
					}
					&.option-3 {
						height: 8.78em;
						background-image: url('../../../../assets/images/modules/tasks/multiple-choice/game-1-email-wrong-address/option-3.svg');
					}	
				
				}
			}	
		}
	}
}