@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.BranchingStory {
	position: relative;
	height: 100%;
	padding-top: 2em;
	overflow: hidden;
	@include background-grey();

	.BranchingStory-story {
		height: calc(100% - 2.5em);
		overflow: auto;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
		@include hide-scrollbar();
		.BranchingStory-step {
			position: relative;
			&.fadeIn {@include fadeIn(0.5s, 'ease', 0.2s);}
			&.arrow {
				margin-bottom: 2.5em;
				&::after {
					content: '';
					position: absolute;
					top: calc(100% + 0.5em);
					left: calc(50% - 10em);
					width: 20em;
					height: 1.5em;
					background-image: url('../../../../assets/images/modules/tasks/branching-story/icon-arrow-down.svg');
				}
			}
			&.fullFadeOut {
				&.arrow::after {
					@include opacity(0.6);
				}
			}
			&.isFacilitator {
				pointer-events: none;
			}
			// &:nth-last-child(1) {
			// 	padding-bottom: 2em;
			// }
		}


	}

	.BranchingStory-nextBtn {
		position: absolute;
		right: 0em;
		bottom: 0.2em;
		z-index: 11;
	}
	.BranchingStory-scrollDown {
		position: fixed;
		bottom: 0.1em;
		left: calc(50% - 1.375em);
		width: 2.75em;
		height: 2.75em;
		background-image: url('../../../../assets/images/modules/icon-down.svg');
		cursor: pointer;
	}

	// &.landscape {

	// }
}