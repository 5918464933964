@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Popup {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#092335, 0.6);
	z-index: 13;
	@include flex('center', 'center');

	.Popup-content {
		position: relative;
		width: 18em;
		height: 18em;
		color: #11466B;
		background-color: white;
		padding: 1em;
		border-radius: 1em;
		text-align: center;
		@include box-shadow(0, 0, 0.66em, 0, rgba(#000000, 0.2));

		.Popup-title {
			font-size: 1.56em;
			font-weight: 700;
			margin-bottom: 0.75em;
		}
		.Popup-text {
			font-size: 1em;
			font-weight: 500;
			margin-top: 0.5em;
		}



		.Popup-buttons {
			margin-left: 10%;
			width: 80%;
			@include flex('space-between');
		}
	}

	&.spot-errors-fail {
		.Popup-contentWrap {
			background-image: url('../../../assets/images/modules/icon-error.svg');
			background-size: 4.5em auto;
			background-position: center bottom;
		}
		.Popup-text {
			padding-bottom: 4em;
		}
	}

	&.spot-errors-result,
	&.clock-task-solved {
		.Popup-content {
			background-image: url('../../../assets/images/modules/icon-check.svg');
			background-size: 4.5em auto;
			background-position: center bottom 4.5em;
		}
	}

	&.order-result {
		.Popup-content {
			@include flex('center', 'center');
			.Popup-title { 
				font-size: 1.5em;
			}
			.Popup-text { 
				margin: 0;
				font-size: 1.25em;
			}
		}
	}
}
