@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorDrawWinnerPopup {
	border-radius: 1em;
	width: 40em;

	.FacilitatorDrawWinnerPopup-header {
		height: 3em;
		padding: 0 0 0 2em;
		background-color: #0F635B;
		border-top-left-radius: 0.278em;
		border-top-right-radius: 0.278em;
		@include flex('space-between','center');
		
		.FacilitatorDrawWinnerPopup-title {
			color: white;
			font-weight: bold;
			font-size: 1.25em;
		}
		
		.FacilitatorDrawWinnerPopup-closeBtn {
			padding: 0em;
			width: 2em;
			height: 2em;
			background-image: url('../../../assets/images/icon-close.svg');
			background-size: 1.75em auto;
			cursor: pointer;
		}
	}
	.FacilitatorDrawWinnerPopup-body {
		color: #0F635B;
		background-color: white;
		padding: 1em 2em;
		border-bottom-left-radius: 0.278em;
		border-bottom-right-radius: 0.278em;

		.FacilitatorDrawWinnerPopup-info {
			p {
				&:nth-child(1) {margin-top: 0;} 
				&:nth-last-child(1) {margin-bottom: 0;}
			}
		}

		.FacilitatorDrawWinnerPopup-participants,
		.FacilitatorDrawWinnerPopup-tickets {
			margin-top: 1em;
			span {
				display: inline-block;
				vertical-align: middle;
				&:nth-child(1) {
					width: 12em;
					font-weight: bold;
				}
			}
		}
		.FacilitatorDrawWinnerPopup-tickets {
			margin-top: 0;
		}

		.FacilitatorDrawWinnerPopup-buttons {
			margin-top: 2em;
			@include flex('space-between', 'center');
			> div {
				width: 17em;
				
			}
		}
		.FacilitatorDrawWinnerPopup-errorMsg {
			margin-top: 0.5em;
			height: 2em;
			span {
				color: #FF856F;
			}
		}
		.FacilitatorDrawWinnerPopup-prevWinners {
			height: 1em;
			text-decoration: underline;
			cursor: pointer;
		}

		.FacilitatorDrawWinnerPopup-winner {
			span {
				display: inline-block;
				vertical-align: middle;
				&:nth-child(1) {
					width: 10em;
				}
			}
		}

	}
}