@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.SortDndContainer {
	position: relative;
	min-height: 4em;
	padding: 0.5em;
	border: 0.16em solid #199D94;
	border-top-style: none;
	border-bottom-left-radius: 0.56em;
	border-bottom-right-radius: 0.56em;
	&.hover {
		background-color: white;
	}
}