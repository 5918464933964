@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.BranchingStoryInfo {
	position: relative;

	.BranchingStoryInfo-textBox {
		position: relative;
		left: calc(50% - 8.75em);
		width: 17.5em;
		color: #11466B;
		background-color: #F5F5F5;
		border-radius: 1.1em;
		padding: 1em 1.25em 1.25em 1.25em;
		margin-bottom: 2em;
		&.bubble {
			top: auto;
			bottom: 20em;
			&::after {
				content: '';
				position: absolute;
				top: calc(100% - 0.5em);
				left: 6.25em;
				border-left: 2em solid #F5F5F5;
				border-bottom: 2.4em solid transparent;
			}
		}
		.BranchingStoryInfo-title {
			font-weight: 700;
			font-size: 1.5em;
			margin-bottom: 0.75em;
		}
		.BranchingStoryInfo-text {
			p {
				margin-bottom: 0;
				font-size: 0.85em;
				&:first-of-type {margin-top: 0;}
			}
		}
	}

	.StoryScreen-character {
		margin: 2em auto 0 auto;
	}
}