@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.BranchingStoryMc {
	position: relative;
	.BranchingStoryMc-intro {
		position: relative;
		width: 17.5em;
		margin: auto;		
		-webkit-transition: all 0.25s ease;
		transition: all 0.25s ease;
	}

	.BranchingStoryMc-options {
		position: relative;
		width: 17.5em;
		margin: 0.5em auto;
		-webkit-transition: all 0.25s ease;
		transition: all 0.25s ease;
		.BranchingStoryMc-option {
			position: relative;
			width: 100%;
			min-height: 2.25em;
			line-height: 1em;
			border-radius: 0.55em;
			font-weight: 700;
			text-align: center;
			padding: 0.75em 3.25em 0.5em 3em;
			margin-bottom: 1.25em;
			cursor: pointer;			
			@include button-grey();
			@include flex('center', 'center');
			@include no-select();
			span {font-size: 0.85em;}
			&.disabled {cursor: not-allowed;}
			&.hidden {display: none;}
			&.selected {
				@include button-green();
				// &::after {
				// 	content: '';
				// 	position: absolute;
				// 	top: 0;
				// 	right: 0.5em;
				// 	width: 2em;
				// 	height: 100%;
				// 	background-image: url('../../../../assets/images/modules/icon-check.svg');
				// }
			}
		}
	}

	&.halfFadeOut {
		.BranchingStoryMc-intro {
			@include opacity(0.6);
		}
	}
	&.fullFadeOut {
		@include opacity(0.6);
	}
}