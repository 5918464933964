@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Button {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.55em;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');

	&.disabled {
		opacity: 0.4;
		cursor: not-allowed;
		pointer-events: none;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: center right;
	}

	&.switch {
		background-color: #CB2D33;
		
		width: 3.5em;
		height: 1.44em;
		line-height: 1.44;
		border-radius: 0.722em;
		padding: 0 0.5em;
		@include flex('flex-end', 'center');
		input {
			position: absolute;	
			opacity: 0;
			width: 0;
			height: 0;
		}
		span {
			position: absolute;
			left: 0.3em;
			display: block;
			height: 1em;
			width: 1em;
			background-color: white;
			border-radius: 100%;
			@include transition(0.1s, 'all', 'ease-in-out');
		}
		strong {
			color: white;
			text-transform: lowercase;
		}
		&.checked {
			background-color: #0F635B;
			@include flex('flex-start', 'center');
			span {
				background-color: #ffffff;
				@include translate(calc(3.5em - 1.6em));
			}
		}
	}


	&.white {
		background-color: white;
		color: #0F635B;	
		border-radius: 1.11em;
		span {
			font-weight: 700;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(white, 5%);
		}		
	}

	&.green {
		background-color: #0F635B;
		color: white;	
		border-radius: 1.11em;
		span {
			font-weight: 700;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#0F635B, 3%);
		}		
	}

	&.green-border {
		border: 1px solid #0F635B;
	}

	&.selectSection {
		border: 1px solid white;
		border-radius: 1.11em;
		&:hover,
		&:focus,
		&:active {
			color: #0F635B;
			background-color: white;
		}	
	}

	&.confirm {
		height: 1.89em;
		border-radius: 0.25em;
		span {
			font-size: 0.75em;
			font-weight: 500;
		}
	}

	&.logout {
		height: 1.33em;
		background-color: rgba(black, 0.4);
		span {
			color: #33E3B5;
			font-size: 0.67em;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: rgba(black, 0.5);
		}
	}

	&.next {
		width: 5em;
		height: 5em;
		border-radius: 5em;
		background-image: url('../../../assets/images/modules/icon-next.svg');
		background-size: 3em auto;
		&.delay {
			@include delayButton();
		}
		&.animate {
			@include opacity(0);
			animation: fadeIn 0.25s ease 0.5s forwards, bounce 0.75s cubic-bezier(0.280, 0.840, 0.420, 1) 0.75s 1 forwards;
			-webkit-animation: fadeIn 0.25s ease 0.5s forwards, bounce 0.75s cubic-bezier(0.280, 0.840, 0.420, 1) 0.75s 1 forwards;
		}
	}

	&.try-again {
		width: 45%;
	}
	
	&.facilitator-back {
		border-radius: 0.56em;
		height: 1.5em;
		background-color: #0F635B;
		border-radius: 100%;
		background-image: url('../../../assets/images/icon-arrow-back.svg');
		background-size: auto 70%;
	}
}