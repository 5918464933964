@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Facilitator {
	position: relative;
	min-height: 100%;
	font-size: calc(18 * ((100vw / 1920)));
	font-weight: 400;
	background-color: white;
	overflow: auto;

	.Facilitator-header {
		position: relative;
		height: 8.75em;
	}

	.Facilitator-content {
		position: absolute;
		width: 100%;
		top: 8.75em;
		bottom: 0;
		overflow: auto;
		padding: 2em 3.5em;
		@include hide-scrollbar();

		.Facilitator-gameStatus {
			position: absolute;
			top: 0.833em;
			left: 55.5em; 
			width: 18em;
			border: 1px solid #0F635B;
			border-radius: 0.278em;
			padding: 1em 1.33em;
			color: #0F635B;
			.Facilitator-gameStatusBtn {
				position: absolute;
				top: 0.67em;
				right: 1.33em;
			}
			.Facilitator-gameStatusTitle {
				span {font-weight: 600;}
			}
			.Facilitator-gameStatusText {
				span {font-size: 0.78em;}
			}
		}
	
		.Facilitator-modules {
			width: 18.25em;
			height: 2.25em;
			border-radius: 1.11em;
			color: #0F635B;
			background-color: #C0DBDA;
			@include flex('space-between', 'center');
			.Facilitator-module {
				width: 8.89em;
				height: 100%;
				border-radius: 1.11em;
				cursor: pointer;
				@include flex('center', 'center');
				&.selected {
					color: white;
					background-color: #0F635B;}
				span {
					font-weight: 600;
				}
			}
		}

		.Facilitator-region {
			position: absolute;
			top: 7em;
			left: 3.5em;
			cursor: pointer;
			@include flex('flex-start', 'center');
			.Facilitator-backBtn {
				width: 1.5em;
				height: 1.5em;
				border-radius: 0.56em;
				background-color: #0F635B;
				border-radius: 100%;
				background-image: url('../../assets/images/icon-arrow-back.svg');
				background-size: auto 70%;
			}
			.Facilitator-regionName {
				margin-left: 0.5em;
				span {
					color: #0F635B;
					font-size: 1.2em;
					font-weight: bold;
				}
			}		
		}
	}

	.Facilitator-popupOverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#7ABCEA, 0.81);
		@include flex('center', 'center');
	}
}