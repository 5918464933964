@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Logo {
	width: 100%;
	height: 2em;
	background-image: url('../../../assets/images/logo.svg');
	background-size: auto 100%;
	background-position: center center;

	&.facilitator {
		width: 9.5em;
		height: 4.6em;
		background-image: url('../../../assets/images/logo-facilitator.svg');
		background-size: 9.28em auto;
		background-position: top left;
	}
}
