@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Results {
	position: relative;
	height: 100%;
	padding: 2em 1em;
	overflow: auto;

	.Results-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(black, 0.2);
	}

	.Results-title {
		position: relative;
		font-size: 1.5em;
		font-weight: 700;
		color: white;
		margin: 0;
		text-align: center;
		margin-bottom: 0.5em;
	}

	.Results-result {
		width: 100%;
		position: relative;
		margin-bottom: 1.5em;
		.Results-resultLabel {
			text-align: center;
			font-size: 0.889em;
			color: white;
			text-align: center;
		}
		.Results-resultValue {
			margin: auto;
			width: 9.5em;
			height: 2.25em;
			border-radius: 1em;
			color: white;
			background-color: #082940;
			margin-top: 0.75em;
			@include flex('center', 'center');
			span {
				font-size: 1.33em;
				font-weight: 700;
			}
		}
	}

	.Results-points {
		margin-top: 1em;
		font-size: 1.25em;
		font-weight: 700;
		color: white;
		text-align: center;
	}

	.Results-errors {
		margin-top: 1em;
		text-align: center;
		color: white;
	}

	.Results-buttons {
		margin: 2em auto 0 auto;
		width: 12.5em;
		.Button {
			margin-top: 1em;
		}
	}

}

@media (orientation: portrait) and (min-aspect-ratio: 375/667) { // wider portrait than design, scale font size after hide
	.Results {
    font-size: calc(18 * ((100vh / 667)));
  }
}