@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorDownloadStats {
	display: inline-block;
	position: relative;
	width: 15em;
	height: 2.25em;
	border-radius: 0.55em;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	color: white;
	background-color: #0F635B;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');	
	&:hover,
	&:focus,
	&:active {
		background-color: darken(#0F635B, 3%);
	}	
}