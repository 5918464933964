@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Instructions {
	position: relative;
	height: 100%;
	padding-top: 3.5em;
	overflow-x: hidden;
	@include background-green();
	.Instructions-info {
		width: 17.25em;
		margin: auto;
		color: white;
	}
	.Instructions-speech {
		position: absolute;
		left: calc(50% - 8.75em);
		bottom: 19em;
		width: 17.5em;
		color: #0F635B;
		background-color: #F5F5F5;
		border-radius: 1.1em;
		padding: 1em 1.25em 1.25em 1.25em;
		&::after {
			content: '';
			position: absolute;
			top: calc(100% - 0.5em);
			left: 6.25em;
			border-left: 2em solid #F5F5F5;
			border-bottom: 3em solid transparent;
		}

		
	}

	.Instructions-title {
		font-weight: 700;
		font-size: 1.5em;
		margin-bottom: 0.75em;
	}

	.Instructions-text {
		p {
			font-size: 1.11em;
			line-height: 1.2;
			margin-bottom: 0;
			&:first-of-type {margin-top: 0;}
		}
		ul {
			margin: 0;
			list-style: none;
			li {
				min-height: 1.3em;
				line-height: 1.2em;
				font-weight: 700;
				padding-left: 1.75em;
				margin-bottom: 0.2em;
				background-image: url('../../../../assets/images/modules/icon-check.svg');
				background-size: 1.2em auto;
				background-position: left center;
				background-repeat: no-repeat;
				&:nth-last-child(1) {margin-bottom: 0;}
			}
		}
		ul + p {
			margin: 0;
		}
	}

	.Instructions-character {
		position: absolute;
		bottom: 0;
		left: 1em;

		&.benjamin-1 {
			position: relative;
			left: auto;
			bottom: auto;
			margin-top: 5em;
			margin-left: 4em;
		}
	}
	

	&.landscape {
		.Instructions-speechBubble { 
			left: calc(50% - 19.5em);
		}
		.Instructions-character {
			left: calc(50% - 11.5em);
		}
	}
}


@media (orientation: portrait) and (min-aspect-ratio: 375/667) { // wider portrait than design, scale font size after hide
	.Instructions {
    font-size: calc(18 * ((100vh / 667)));
  }
}