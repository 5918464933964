@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Character {
	background-size: auto 100%;
	background-position: bottom right;

	&.flipped {
		@include scaleX(-1);
	}

	&.benjamin-1 {
		display: inline-block;
		width: 13.56em;
		height: 13.56em;
		background-image: url('../../../assets/images/characters/benjamin-1.svg');
	}

	&.manager-1 {
		width: 16.39em;
		height: 15.59em;
		background-image: url('../../../assets/images/characters/manager-1.svg');
		background-position: bottom -0.1em left;
		&.home {
			margin: 0 auto;
			width: 12.5em;
			height: 11.86em;
			@include translate(-4.5em, 0);
		}
	}
	&.manager-2 {
		height: 15.59em;
		width: 16.39em;
		background-image: url('../../../assets/images/characters/manager-2.svg');
		background-position: bottom -0.1em left;
	}


}