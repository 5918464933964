@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.SelectRegion {
	position: relative;
	height: 100%;
	padding: 1em;
	text-align: right;
	@include background-gradient();

	.SelectRegion-title {
		margin-top: 2em;
		margin-bottom: 5em;
		text-align: center;
		p {
			margin: 0;

			text-transform: uppercase;
			font-size: 1.55em;
			line-height: 1.2;
			font-weight: 700;
		}
	}

	.SelectRegion-text {
		text-align: left;
		padding: 0 calc(50% - 7.25em);
		margin-bottom: 1em;
		font-weight: 500;
	}
	.SelectRegion-regions {
		text-align: left;
		padding: 0 calc(50% - 7.25em);
		margin-bottom: 1em;
		.SelectRegion-region {
			position: relative;
			height: 2.11em;
			border: 1px solid white;
			border-radius: 0.25em;
			padding: 0 0.85em;
			margin-bottom: 0.56em;
			@include flex('flex-start', 'center');
			span {
				font-size: 0.89em;
				font-weight: 500;
			}
			&::after {
				content: '';
				position: absolute;
				right: 0.6em;
				top: 0.556em;
				width: 1em;
				height: 1em;
				border-radius: 100%;
				border: 1px solid white;
			}
			&.selected {
				color: #0F635B;
				background-color: white;
				&::after {
					border: 0.25em solid #199D94;
				}
			}
		}
	}
	.SelectRegion-okBtn {
		display: inline-block;
		margin-right: calc(50% - 7.25em);
		width: 7em;
	}
}

@media (orientation: portrait) and (min-aspect-ratio: 375/667) { // wider portrait than design, scale font size after height
	.SelectRegion {
    font-size: calc(18 * ((100vh / 667)));
  }
}
