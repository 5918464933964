@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';

.FacilitatorGameStatus {
	position: absolute;
	top: 0.833em;
	left: 53.5em; 
	width: 20em;
	border: 1px solid #0F635B;
	border-radius: 0.278em;
	padding: 1em 1.33em;
	color: #0F635B;
	.FacilitatorGameStatus-button {
		position: absolute;
		top: 0.67em;
		right: 1.33em;
	}
	.FacilitatorGameStatus-title {
		span {font-weight: 600;}
	}
	.FacilitatorGameStatus-text {
		span {font-size: 0.78em;}
	}
}