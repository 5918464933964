@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Sort {
	position: relative;
	height: 100%;
	padding-top: 2em;
	padding-bottom: 1em;
	overflow: hidden;
	@include background-grey();

	.Sort-content {
		position: absolute;
		top: 5em;
		bottom: 0;
		width: 20.5em;
		left: calc(50% - 10.25em);
		overflow: auto;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
		@include hide-scrollbar();


		.Sort-intro {
			position: relative;
			width: 17.5em;
			margin: 0 auto;
		}
	
		.Sort-categories {
			width: 18em;
			margin: 0 auto 1em auto;
			.Sort-category {
				margin-bottom: 0.5em;
				border-radius: 0.56em;
				background-color: #D9D9D9;
				.Sort-categoryTitle {
					@include flex('center', 'center');
					height: 2em;
					color: white;
					background-color: #199D94;
					border-top-left-radius: 0.56em;
					border-top-right-radius: 0.56em;
					span {
						font-weight: 700;
					}
				}
			}
		}
		.Sort-items {
			width: 18em;
			margin: 0 auto 1em auto;
		}

		.Sort-scrollDown {
			position: fixed;
			bottom: 0.1em;
			left: calc(50% - 1.375em);
			width: 2.75em;
			height: 2.75em;
			background-image: url('../../../../assets/images/modules/icon-down.svg');
			cursor: pointer;
		}
	}
	
	

}