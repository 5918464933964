@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Order2 {
	position: relative;
	height: 100%;
	padding-top: 2em;
	padding-bottom: 1em;
	overflow: hidden;
	@include background-grey();

	.Order2-content {
		position: absolute;
		top: 5em;
		bottom: 0;
		width: 17.5em;
		left: calc(50% - 8.75em);
		overflow: auto;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
		@include hide-scrollbar();
	}
	.Order2-intro {
		position: relative;
		width: 17.5em;
		margin: 0 auto 1em auto;
	}

	.Order2-hiddenItems {
		position: relative;
		width: 100%;
		margin: 0.75em auto auto auto;
		.Order2-hiddenItem {
			width: 100%;
			margin-bottom: 1.25em;
			min-height: 2.25em;
			line-height: 1.25em;
			border-radius: 0.55em;
			font-weight: 700;
			text-align: center;
			padding: 0.75em 2em 0.5em 2em;
			pointer-events: none;
			@include opacity(0);
			@include button-grey();
			@include flex('center', 'center');
			@include no-select();
			span {
				color: white;
				font-size: 0.85em;
				font-weight: 700;
			}	
		}
	}

	.Order2-items {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.Order2-item {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			
			margin-bottom: 1.25em;
			min-height: 2.25em;
			line-height: 1.25em;
			border-radius: 0.55em;
			font-weight: 700;
			text-align: center;
			padding: 0.75em 2em 0.5em 2em;
			cursor: pointer;
			@include button-grey();
			@include flex('center', 'center');
			@include no-select();
			span {
				color: white;
				font-size: 0.85em;
				font-weight: 700;
			}
		
			&.correct {@include button-green();}
			&.selected {
				@include button-yellow();
			}
			
		}
	}

	.Order2-doneBtn {
		position: fixed;
		right: calc(50% - 8.5em);
		bottom: 1em;
		z-index: 2;
		width: 7em;
	}

	.Order2-scrollDown {
		position: fixed;
		bottom: 0.1em;
		left: calc(50% - 1.375em);
		width: 2.75em;
		height: 2.75em;
		background-image: url('../../../../assets/images/modules/icon-down.svg');
		cursor: pointer;
	}


	&.landscape {
		.Order2-doneBtn {
			right: calc(50% - 16.5em);
		}
	}
}