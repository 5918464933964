@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.SortDndItem {
	position: relative;
	display: inline-block;
	border-radius: 0.56em;
	color: white;
	width: 100%;
	background-color: #9B9A9E;
	padding: 0.5em 2em 0.5em 1em;
	margin-bottom: 0.75em;
	line-height: 1em;
	background-image: url('../../../../assets/images/modules/icon-move.svg');
	background-position: top 0.5em right 0.5em;
	background-size: auto 1em;
	@include box-shadow(0, 0.2em, 0.25em, 0, rgba(#000000, 0.2));
	cursor: move;
	span {
		font-size: 0.75em;
		font-weight: 700;
	}
	&.disabled {
		cursor: not-allowed;
		background-image: none;
	}
	&.completed,
	&.animateCorrect {
		background-color: #40E2B6;
		background-image: radial-gradient(#40E2B6, #239D94);
		background-size: 100% 100%;
		background-position: center center;
		padding: 0.5em 1em;
		@include box-shadow-inset-outset(
			0, 0.2em, 0, 0, rgba(#76E0B8, 0.5), 
			0, 0.2em, 0.25em, 0, rgba(#000000, 0.2)
		);
	}
	&.animateCorrect {
		@include animateCorrect();
	}
	&.animateWrong {
		background-color: #FF856F;
		background-image: url('../../../../assets/images/modules/icon-move.svg'), radial-gradient(#FF856F, #CB2D33);
		background-size: auto 1em, 100% 100%; 
		background-position: top 0.5em right 0.5em, center center;
		@include animateWrong();
	}
}