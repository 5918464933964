@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Module {
	position: relative;
	height: 100%;
	overflow: auto;

	.Module-header {
		position: absolute;
		top: 0;
		width: 100%;
		padding-top: 1.25em;
		z-index: 11;
		@include flex('center', 'flex-start');
	
		.Module-homeBtn,
		.Module-highscoreBtn {
			position: absolute;
			width: 3.5em;
			height: 3.5em;
			top: 0;
			background-size: 1.89em auto;
			background-position: center center;
			cursor: pointer;
			
		}
		.Module-homeBtn {
			left: 0;
			background-image: url('../../../assets/images/icon-home.svg');
		}
		.Module-highscoreBtn {
			right: 0;
			background-image: url('../../../assets/images/icon-trophy.svg')
		}
		.Module-pointsTrack {
			width: 14.89em;
		}
	}


	.Module-nextBtn {
		position: absolute;
		right: 0em;
		bottom: 0.2em;
		z-index: 11;

		&.done,
		&.finish {
			right: 1.5em;
			bottom: 1.5em;
			width: 7em;
		}
	}


	&.landscape {
		.Module-storeBtn {margin-right: 0.75em;}
		.Module-pointsTrack {width: 18em;}
		.Module-profileBtn {margin-left: 0.75em;}
		.Module-nextBtn {right: calc(50% - 13.5em);}
	}
}