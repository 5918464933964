@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Highscore {
	position: relative;
	height: 100%;
	padding: 5em 1em 1em 1em;
	@include background-gradient();

	.Highscore-prevBtn {
		position: absolute;
		width: 3.5em;
		height: 3.5em;
		top: 0;
		left: 0;
		background-image: url('../../../assets/images/icon-prev.svg');
		background-size: 2.67em auto;
		background-position: center center;
		cursor: pointer;
		
	}

	.Highscore-content {
		padding-top: 3em;
		background-image: url('../../../assets/images/trophy.svg');
		background-size: 5.25em auto;
		background-position: top right;
		.Highscore-text {
			text-align: left;
			margin-bottom: 1em;	
			
			span {
				display: block;
				font-weight: 700;
				text-transform: uppercase;
				&:nth-of-type(1) {
					font-size: 1.33em;
				}
				&:nth-of-type(2) {
					font-size: 0.89em;
				}
			}
		}
	
	
		.Highscore-table {
			border-radius: 0.56em;
			padding: 1em;
			background-color: white;
			border-bottom: 1px solid transparent;
			&.showUserHighscore {
				border-bottom-right-radius: 0em;
				border-bottom-left-radius: 0em;
				padding: 1em 1em 0.33em 1em;
				border-color: 1px solid #D7D7D8;
			}
			.Highscore-row {
				height: 2em;
				line-height: 1.5;
				background-color: #D7D7D8;
				padding: 0 1em;
				@include flex('space-between', 'center');
				&:nth-child(even) {
					background-color: #EFEFEF;
				}
				span {
					color: #0F635B;
					font-weight: 600;
					&:nth-child(1) {width: 1.5em;}
					&:nth-child(2) {
						// width: calc(100% - 3.5em);
						width: calc(100% - 1.5em);
						white-space: nowrap;
						overflow: hidden;
					}
					// &:nth-child(3) {
					// 	width: 2em;
					// 	text-align: right;
					// }
				}
				&.user {
					background-color: #0F635B;
					span {
						color: white;
						font-weight: 700;
					}
				}
			}
		}
	
		.Highscore-userScore {
			padding: 0.33em 1em 1em 1em;
			border-bottom-left-radius: 0.56em;
			border-bottom-right-radius: 0.56em;
			background-color: #EFEFEF;
			.Highscore-userRow {
				height: 2em;
				line-height: 1;
				background-color: #0F635B;
				padding: 0 1em;
				@include flex('space-between', 'center');
				span {
					color: white;
					font-weight: 700;
					&:nth-child(1) {width: 1.5em;}
					&:nth-child(2) {
						// width: calc(100% - 3.5em);
						width: calc(100% - 1.5em);
						white-space: nowrap;
						overflow: hidden;
						span {
							font-weight: 400;
						}
					}
					// &:nth-child(3) {
					// 	width: 2em;
					// 	text-align: right;
					// }
				}
			}
		}
	}
}

@media (orientation: portrait) and (min-aspect-ratio: 375/667) { // wider portrait than design, scale font size after height
	.Highscore {
    font-size: calc(18 * ((100vh / 667)));
  }
}
