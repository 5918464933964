@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Carousel {
	position: relative;
	width: 100%;
	height: 100%;
	.Carousel-scrollLeftBtn,
	.Carousel-scrollRightBtn {
		position: absolute;
		top: 0.75em;
		width: 2.75em;
		height: 2.75em;		
		background-size: auto 100%;
		@include opacity(0.4);
		cursor: not-allowed;
		&.active {
			@include opacity(1);
			cursor: pointer;
		}
	}
	.Carousel-scrollLeftBtn {
		left: -3.25em;
		background-image: url('../../../../assets/images/modules/tasks/dialogue/icon-prev.svg');
	}
	.Carousel-scrollRightBtn {
		right: -3.25em;
		background-image: url('../../../../assets/images/modules/tasks/dialogue/icon-next.svg');
	}

	.Carousel-options {
		position: relative;
		width: 100%;
		height: 6.2em;
		overflow: hidden;
		.Carousel-option {
			position: absolute;
			width: 14.25em;
			min-height: 4.25em;
			max-height: 5.8em;
			color: #0F635B;
			border-radius: 1em;
			background-color: #ffffff;
			will-change: transform;
			padding: 0.5em 2em 0.5em 1em;
			touch-action: none;
			line-height: 1.22;
			@include no-select();
			&.dialogue {
				
				color: #11466B;
				background-color: #F5F5F5;
			}
			.Carousel-selectBtn {
				position: absolute;
				top: 0.25em;
				right: 0.35em;
				width: 1.45em;
				height: 1.45em;
				background-image: url('../../../../assets/images/modules/tasks/dialogue/icon-select-answer.svg');
				background-size: contain;
				cursor: pointer;
				@include no-select();
			}
		}
	}
	.Carousel-navigation {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1.5em;
		text-align: center;

		
		.Carousel-navigationDot {
			display: inline-block;
			width: calc(0.75em + 1px);
			height: calc(0.75em + 1px);
			border-radius: 1em;
			margin: 0 0.350em;
			svg {
				width: 100%;
				fill:rgba(#FFFFFF, 0.35)
			}
			&.selected svg {
				fill: #ffffff;
			}
		}
	}
}