@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Progress {
	width: 100%;
	height: 1em;
	text-align: center;
	margin: 0.5em 0 0.5em 0;
}
