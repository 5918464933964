@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Intro {
	position: relative;
	height: 100%;
	padding: 1em;
	overflow: auto;
	@include background-gradient();

	.Intro-title {
		margin-top: 1.5em;
		text-align: center;
		p {
			margin: 0;
			text-transform: uppercase;
			font-size: 1.55em;
			line-height: 1.2;
			font-weight: 700;
		}
	}
	.Intro-text {
		width: 17.25em;
		margin: 1em auto 0 auto;
		color: #0F635B;
		background-color: white;
		border-radius: 0.25em;
		padding: 0.5em 0.8em;
		p {
			font-size: 0.89em;
			margin-top: 0;
			&:last-child {margin-bottom: 0;}
		}
	}

	.Intro-startBtn {
		margin: 1em auto auto auto;
		width: 8.8em;
		height: 2.25em;
	}
}

@media (orientation: portrait) and (min-aspect-ratio: 375/667) { // wider portrait than design, scale font size after height
	.Intro {
    font-size: calc(18 * ((100vh / 667)));
  }
}
