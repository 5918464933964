@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.TaskIntro {
	position: relative;
	width: 100%;		
	.TaskIntro-text {
		border-radius: 1.1em;
		color: white;
		padding-bottom: 0.5em;
		p {
			margin-top: 0;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		span {
			display: inline-block;
			margin-top: 1em;
			font-weight: 700;
		}
	}

	.TaskIntro-image {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-size: 5.22em auto;
			background-position: bottom right;
	}

	&.benjamin {
		.TaskIntro-image {background-image: url('../../../../assets/images/modules/tasks/task-intro/benjamin.svg');}
		.TaskIntro-text {padding-bottom: 4.5em;}
	}

	&.box {
		.TaskIntro-image {background-image: url('../../../../assets/images/modules/tasks/task-intro/box.svg');}
		.TaskIntro-text {padding-bottom: 2em;}
	}

	&.button {
		.TaskIntro-image {background-image: url('../../../../assets/images/modules/tasks/task-intro/button.svg');}
		.TaskIntro-text {padding-bottom: 2em;}
	}
	&.button2 {
		.TaskIntro-image {background-image: url('../../../../assets/images/modules/tasks/task-intro/button.svg');}
		.TaskIntro-text {padding-bottom: 3em;}
	}

	&.documents,
	&.documents2 {
		.TaskIntro-image {background-image: url('../../../../assets/images/modules/tasks/task-intro/documents.svg');}
		.TaskIntro-text {padding-bottom: 2em;}
	}
	&.documents2 {
		.TaskIntro-text {padding-bottom: 4em;}
	}

	&.email {
		.TaskIntro-image {background-image: url('../../../../assets/images/modules/tasks/task-intro/email.svg');}
		.TaskIntro-text {padding-bottom: 2.35em;}
	}

	&.folder-firstaid {
		.TaskIntro-image {background-image: url('../../../../assets/images/modules/tasks/task-intro/folder-firstaid.svg');}
		.TaskIntro-text {padding-bottom: 3em;}
	}

	&.folder-lock,
	&.folder-lock2,
	&.folder-lock3 {	
		.TaskIntro-image {background-image: url('../../../../assets/images/modules/tasks/task-intro/folder-lock.svg');}
		.TaskIntro-text {padding-bottom: 3em;}
	}
	&.folder-lock2 .TaskIntro-text {padding-bottom: 5.2em;}
	&.folder-lock3 .TaskIntro-text {padding-bottom: 4em;}

	&.monitor {
		.TaskIntro-image {background-image: url('../../../../assets/images/modules/tasks/task-intro/monitor.svg');}
		.TaskIntro-text {padding-bottom: 2.35em;}
	}
}
