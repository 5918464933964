@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorHeader {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #199D94;
	background-image: linear-gradient(#106E68, rgba(#179D94, 0.8));

	.FacilitatorHeader-logo {
		position: absolute;
		top: 1.1em;
		left: 1.1em;
	}

	.FacilitatorHeader-logoutBtn {
		position: absolute;
		top: 1.1em;
		right: 1.1em;
		width: 2.5em;
		height: 3em;
		background-image: url('../../../assets/images/icon-logout.svg');
		cursor: pointer;
	}
}