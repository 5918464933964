@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.SortDndDragLayer {
	.SortDndDragLayer-item {
		width: 18em;
		border-radius: 0.56em;
		color: white;
		background-color: #9B9A9E;
		padding: 0.5em 2em 0.5em 1em;
		line-height: 1em;
		background-image: url('../../../../assets/images/modules/icon-move.svg');
		background-position: top 0.5em right 0.5em;
		background-size: auto 1em;		
		transform-origin: right 0.5em center;
		@include rotate(-7deg);
		@include box-shadow(0, 0.2em, 0.25em, 0, rgba(#000000, 0.2));
		span {
			font-size: 0.75em;
			font-weight: 700;
		}		
	}
}