@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Dialogue {
	position: relative;
	height: 100%;
	overflow: hidden;
	@include background-grey();

	&.intro,
	&.result {
		padding-top: 4em;
		background: none;
		.Dialogue-introText,
		.Dialogue-resultText {
			position: relative;
			left: calc(50% - 8.75em);
			width: 17.5em;
			color: #11466B;
			background-color: #F5F5F5;
			border-radius: 1.1em;
			padding: 1em 1.25em 1.25em 1.25em;

			p {	
				margin-bottom: 0;
				&:first-of-type {margin-top: 0;}
			}
			&.speech,
			&.dialogue {
				position: absolute;
				bottom: 19em;
				&::after {
					content: '';
					position: absolute;
					top: calc(100% - 0.5em);
					left: 6.25em;
					border-left: 2em solid #F5F5F5;
					border-bottom: 2.4em solid transparent;
				}
			}
		}			
		.Dialogue-character {
			position: absolute;
			left: calc(50% - 11.75em);
			bottom: 0;
			z-index: 3;
			
		}

		.Dialogue-item {
			position: absolute;
			bottom: 0;
		
		}
		.Dialogue-player {
			position: absolute;
			right: calc(50% - 10.75em);
			bottom: -0.1em;			
		}
		.Dialogue-startBtn {
			position: absolute;
			left: calc(50% + 1em);
			bottom: 2em;
			width: 8em;
			z-index: 5;
		}
	}


	.Dialogue-wrap {
		position: absolute;
		bottom: 0;
		left: calc(50% - 10.4167em);
		width: 20.833em;
		height: 100%;

		.Dialogue-dialogue {
			position: relative;
			width: 100%;
			height: calc(100% - 10.5em);
			.Dialogue-lines {
				position: absolute;
				left: 0;
				bottom: 0.25em;
				width: 100%;
				max-height: calc(100% - 1em);
				padding: 0 1.55em;
				overflow: auto;
				display: table-cell; 
				vertical-align: bottom;
				@include hide-scrollbar();
				scroll-behavior: smooth;
				-webkit-overflow-scrolling: touch;
				.Dialogue-line {
					position: relative;
					width: 100%;
					text-align: center;
					margin: 0 0 1em 0;
					.Dialogue-bubble {
						position: relative;
						display: inline-block;
						width: 14.25em;
						background-color: #FFFFFF;
						color: #0F635B;
						padding: 0.5em 1em 0.5em 3em;
						border-radius: 1.1em;
						text-align: left;
						line-height: 1.22em;
					}
					.Dialogue-personAvatar {
						position: absolute;
						bottom: -0.35em;
						left: -0.75em;
						width: 3.25em;
						height: 3.25em;
						background-color: white;
						border-radius: 100%;
						border: 0.15em solid #199D94;
						&.person-1 {
							background-size: 3.68em auto;
							background-image: url('../../../../assets/images/characters/person-1.svg');
						}
						&.person-2 {
							background-size: 3.25em auto;
							background-image: url('../../../../assets/images/characters/person-2.svg');
						}

						&.person-1-flipped {
							background-position: top 0.25em right 0em !important;
							-webkit-transform: scaleX(-1);
							transform: scaleX(-1);
							background-size: 3.68em auto;
							background-image: url('../../../../assets/images/characters/person-1.svg');
						}
						&.person-2-flipped {
							background-position: top 0.25em left 0.1em !important;
							-webkit-transform: scaleX(-1);
							transform: scaleX(-1);
							background-size: 3.25em auto;
							background-image: url('../../../../assets/images/characters/person-2.svg');
						}
					}
					&.dialogue {
						margin: 0 0 1.75em 0;
						&.customer {
							text-align: left;
							.Dialogue-bubble {
								background-color: #199D94;
								color: white;
							}
							.Dialogue-personAvatar {
								background-color: #199D94;
								border-color: white;	
								background-position: top 0.25em right 0.1em;							
							}
						}
						&.player {
							text-align: right;
							.Dialogue-bubble {
								text-align: left;
								padding: 0.5em 3em 0.5em 1em;
							}
							.Dialogue-personAvatar {
								left: unset;
								right: -1.25em;
								background-position: top 0.25em left 0em;
							}
						}
					}
					&.action {
						.Dialogue-bubble {
							padding: 0.5em 1em;
							background-color: #C3E5E3;
						}
						.Dialogue-personAvatar {
							display: none;
						}
					}
				}
			}
		}
		.Dialogue-panel {
			position: absolute;
			width: 100%;
			height: 10.5em;
			background-color: #199D94;
			border-top: 0.167em solid #4CBEA6;
			// overflow: hidden;
			bottom: 0;
			.Dialogue-options {
				padding-top: 0.5em;
				height: 100%;
				.Dialogue-optionsText {
					color: white;
					text-align: center;
					font-weight: 700;
				}
				.Dialogue-optionsCarousel {
					width: 100%;
					height: 7.5em;
					margin-top: 0.67em;
				}
			}

			.Dialogue-points {
				width: 100%;
				height: 100%;
				padding-top: 3.5em;
				text-align: center;
				span {
					color: white;
					font-size: 1.75em;
					font-weight: 700;
				}
			}
		}
	}
	.Dialogue-nextBtn {
		position: absolute;
		right: 0;
		bottom: 0;
	}


	&.landscape {
		
		.Dialogue-nextBtn {right: calc(50% - 13.5em);}
	}
}

@media (orientation: portrait) and (min-aspect-ratio: 375/667) { // wider portrait than design, scale font size after hide
	.Dialogue {
		&.intro,
		&.result {
    	font-size: calc(18 * ((100vh / 667)));
		}
  }
}