@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorOverviewStats {
	position: relative;
	.FacilitatorOverviewStats-table {
		margin-top: 5em;
		margin-bottom: 3em;
		width: 70em;
		.FacilitatorOverviewStats-tableHeader {
			width: 100%;
			height: 2em;
			background-color: #0F635B;
			border-top-left-radius: 0.278em;
			border-top-right-radius: 0.278em;
			padding: 0 1em;
			@include flex('space-between', 'center');
			.FacilitatorOverviewStats-tableCell {
				font-weight: bold;
			}
		}
		.FacilitatorOverviewStats-tableRow {
			width: 100%;
			height: 2em;
			background-color: #D7D7D8;
			padding: 0 1em;
			cursor: pointer;
			@include flex('space-between', 'center');
			&:nth-child(even) {background-color: #EFEFEF;}
			&:hover {
				background-color: darken(#D7D7D8, 2%);
				&:nth-child(even) {background-color: darken(#EFEFEF, 2%);}
			}
			.FacilitatorOverviewStats-tableCell {
				color: #0F635B;
				font-weight: 600;
				padding-left: 2em;
				&:nth-child(1) {padding-left: 0;}
			}
		}
		.FacilitatorOverviewStats-tableCell {
			width: 20%;
		}
	}

	.FacilitatorOverviewStats-downloadBtn {
		display: inline-block;
		span {
			display: inline-block;
		}
	}

}