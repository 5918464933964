@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorTaskPopup {
	margin-top: -2.25em;
	margin-left: 1em;
	background-color: #199D94;
	border-radius: 1em;
	border-style: solid;
	border-width: 0.25em;
	border-color: white;
	padding: 0.5em;

	.FacilitatorTaskPopup-header {
		@include flex('space-between','','row');
		@include no-select();
		padding-bottom: 0.25em;
		.FacilitatorTaskPopup-title {
			color: white;
			span {
				font-size: 1.5em;
				font-weight: bold;
			}
		}
		
		.FacilitatorTaskPopup-closeBtn {
			padding: 0em;
			width: 2em;
			height: 2em;
			background-image: url('../../../assets/images/icon-close.svg');
			background-size: 1.75em auto;
			cursor: pointer;
		}
	}
	.FacilitatorTaskPopup-taskSidebarBody {
		@include flex('', '', 'row');
		height: 20em;
		padding-bottom: 0.25em;
		.FacilitatorTaskPopup-task {
			overflow: hidden;
			border-radius: 2em;
			font-size: 0.5em;
			height: 100%;
			width: 25em;
		}

		.FacilitatorTaskPopup-statistics {
			@include flex('space-between', 'center', 'column');
			width: 11em;
			height: 100%;
			color: white;
			padding-left: 0.5em;
			padding-right: 0.5em;

			.FacilitatorTaskPopup-statisticsHeader {
				@include flex('', 'center', 'column');
				.FacilitatorTaskPopup-statisticsResult {
					font-weight: bold;
					font-size: 2em;
				}
			}
		}
	}
	.FacilitatorTaskPopup-taskSidebarFooter {
		@include flex('space-between','center','row');
		bottom: 0;
		width: 100%;
		.FacilitatorTaskPopup-previous {
			background-image: url('../../../assets/images/modules/icon-next.svg');
			width: 2em;
			height: 2em;
			background-size: 1.75em auto;
			@include scaleX(-1);
			cursor: pointer;
		}
		.FacilitatorTaskPopup-taskText {
			@include no-select();
			color: white;
			font-weight: bold;
		}
		.FacilitatorTaskPopup-next {
			background-image: url('../../../assets/images/modules/icon-next.svg');
			width: 2em;
			height: 2em;
			background-size: 1.75em auto;
			cursor: pointer;
		}
	}
}