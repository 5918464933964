@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorRegionStats {
	position: relative;


	.FacilitatorRegionStats-table {
		margin-top: 5em;
		width: 70em;
		.FacilitatorRegionStats-tableHeader {
			width: 100%;
			height: 2em;
			background-color: #0F635B;
			border-top-left-radius: 0.278em;
			border-top-right-radius: 0.278em;
			padding: 0 1em;
			@include flex('space-between', 'center');
			.FacilitatorRegionStats-tableCell {
				font-weight: bold;
			}
		}
		.FacilitatorRegionStats-tableRow {
			width: 100%;
			height: 2em;
			background-color: #D7D7D8;
			padding: 0 1em;
			cursor: pointer;
			@include flex('space-between', 'center');
			&:nth-child(even) {background-color: #EFEFEF;}
			&:hover {
				background-color: darken(#D7D7D8, 2%);
				&:nth-child(even) {background-color: darken(#EFEFEF, 2%);}
			}
			.FacilitatorRegionStats-tableCell {
				color: #0F635B;
				font-weight: 600;
				padding-left: 2em;
				&:nth-child(1), &:nth-child(2) {padding-left: 0;}

			}
		}
		.FacilitatorRegionStats-tableCell {
			width: 25%;
			&:nth-child(1) {width: 20%;}
			&:nth-child(2) {width: 30%;}
		}
	}

}