@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Loading {
	position: relative;
	height: 100%;
	background-image: linear-gradient(#106E68, rgba(#179D94, 0.7), rgba(#179D94, 0));
	background-size: 100% 100%;
	@include flex('center', 'center');
	.Loading-text {
		color: white;
	}
}
