@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.SelectSection {
	position: relative;
	min-height: 100%;
	font-size: calc(18 * ((100vw / 1920)));
	font-weight: 400;
	overflow: auto;
	background-image: linear-gradient(#106E68, rgba(#179D94, 0.7), rgba(#179D94, 0));
	background-size: 100% 45em;
	background-position: top center;
	@include flex('center', 'center', 'row');
	&.portrait {
		font-size: calc(18 * ((100vw / 667)));
		@include flex('center', 'center', 'column');
		.SelectSection-or {margin: 2em auto;}
	}

	.SelectSection-logo {
		position: absolute;
		top: 1.1em;
		left: 1.1em;
	}

	.SelectSection-logoutBtn {
		position: absolute;
		top: 1.1em;
		right: 1.1em;
		width: 2.5em;
		height: 3em;
		background-image: url('../../assets/images/icon-logout.svg');
		cursor: pointer;
	}

	.SelectSection-section {
		width: 17.75em;
		height: 9.25em;
		border-radius: 0.56em;
		background-color: #0F635B;
		padding: 1em 2em;
		.SelectSection-sectionText {
			text-align: center;
			span {
				font-size: 1.11em;
				color: white;
			}	
		}
		.SelectSection-sectionBtn {
			width: 8.89em;
			margin: 2em auto 0 auto;
		}
	}

	.SelectSection-or {
		width: 11em;
		text-align: center;
		span {
			font-size: 2em;
			font-weight: 600;
			color: white;
		}	
	}
}